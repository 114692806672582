<template>
  <div>
    <v-container fluid class="layout-content pa-0 w-full">
      <div id="agentData">
        <v-card class="text-center pa-0" elevation="0" style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2) inset !important">
          <!-- <car-trust-header></car-trust-header> -->
          <v-card-title class="justify-center" style="font-weight: bolder; font-size: xx-large; padding-top: 80px;"
            >แบบฟอร์มลงทะเบียนรีไฟแนนซ์</v-card-title
          >
          <v-card-text class="pa-0">
            <v-row>
              <v-col
                offset-lg="1"
                lg="10"
                offset-md="1"
                md="10"
                offset-sm="1"
                sm="10"
                cols="12"
                :align-self="'center'"
              >
                <v-row dense>
                  <v-col cols="12" :align-self="'center'">
                    <!-- <h3 class="text-start">ข้อมูลรถ</h3> -->
                    <v-container id="carInfo">
                      <v-form
                        ref="form1"
                        v-model="valid1"
                        lazy-validation
                      >
                        <v-row dense>
                          <v-col cols="12" md="6" lg="4">
                            <v-text-field
                              v-model="lead.first_name"
                              label="* ชื่อ"
                              outlined
                              required
                              background-color="#EEF4FF"
                              dense
                              :rules="requiredRules"
                            />
                          </v-col>
                          <v-col cols="12" md="6" lg="4">
                            <v-text-field
                              v-model="lead.last_name"
                              label="* นามสกุล"
                              outlined
                              required
                              background-color="#EEF4FF"
                              dense
                              :rules="requiredRules"
                            />
                          </v-col>
                          <v-col cols="12" md="6" lg="4">
                            <v-text-field
                              v-model="lead.phone1"
                              label="* เบอร์โทรติดต่อ"
                              outlined
                              required
                              background-color="#EEF4FF"
                              dense
                              :rules="requiredRules"
                            />
                          </v-col>
                        </v-row>
                        <v-row dense><span>ท่านได้รับแนะนำแบบฟอร์มลงทะเบียนผ่านช่องทางใด?</span></v-row>
                        <v-row dense>
                          <v-col lg="4" md="6" sm="6" cols="12">
                            <v-autocomplete
                              v-model="channel_select"
                              outlined
                              label="* ช่องทาง"
                              :items="[...OFFLINE_BRANCH, ...ONLINE_BRANCH]"
                              required
                              readonly
                              background-color="#EEF4FF"
                              dense
                              :rules="requiredRules"
                              item-value="name"
                              item-text="name"
                            >
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6" lg="4">
                            <v-autocomplete
                              v-model="lead.brand"
                              auto-select-first
                              outlined
                              deletable-chips
                              placeholder="Brand"
                              label="* ยี่ห้อ / Brand"
                              required
                              background-color="#EEF4FF"
                              dense
                              :rules="requiredRules"
                              :items="getValidBrands"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6" lg="4">
                            <v-autocomplete
                              v-model="lead.model"
                              persistent-hint
                              auto-select-first
                              outlined
                              placeholder="Model"
                              label="* รุ่น / Model *"
                              :items="getModels"
                              required
                              background-color="#EEF4FF"
                              dense
                              :rules="requiredRules"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6" lg="4">
                            <v-autocomplete
                              v-model="lead.year"
                              auto-select-first
                              outlined
                              placeholder="Year"
                              label="* ปีรถ / Car Year *"
                              :items="getValidYears"
                              required
                              background-color="#EEF4FF"
                              dense
                              :rules="requiredRules"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6" lg="4">
                            <v-autocomplete
                              v-model="lead.sub_model"
                              auto-select-first
                              outlined
                              placeholder="รุ่นย่อย / Sub-Model "
                              label="* รุ่นย่อย / Sub-Model *"
                              :items="getValidSubModelList"
                              required
                              background-color="#EEF4FF"
                              dense
                              :rules="requiredRules"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="1"></v-col>
                          <v-col cols="5">
                            <v-card style="background-color: #0139A1; padding-top: 20px; padding-bottom: 20px;">
                              <p style="color: white; font-weight: bold; font-size: medium;">ราคาประเมินเบื้องต้น</p>
                              <p style="color:#ed8b24; font-size: xx-large; font-weight: bolder;">{{
                                getPrice
                                  | formatCurrency({
                                    decimalDigits: 0,
                                    decimalSeparator: ".",
                                    thousandsSeparator: ",",
                                  })
                              }}
                              {{
                                typeof getPrice != "string" ? " ฿" : ""
                              }}</p>
                            </v-card>
                          </v-col>
                          <v-col cols="5">
                            <v-card style="background-color: #0139A1; padding-top: 20px; padding-bottom: 20px;">
                              <p style="color: white; font-weight: bold; font-size: medium;">ยอดจัดสูงสุด 1.5 เท่า*</p>
                              <p style="color:#ed8b24; font-size: xx-large; font-weight: bolder;">
                              {{
                                getPrice150
                                  | formatCurrency({
                                    decimalDigits: 0,
                                    decimalSeparator: ".",
                                    thousandsSeparator: ",",
                                  })
                              }}
                              {{
                                typeof getPrice != "string" ? " ฿" : ""
                              }}</p>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>

                    <!-- <div>
                      <v-card
                        class="overflow-auto mx-auto"
                        height="200px"
                        width="500px"
                      >
                        <div class="my-iframe-container">
                          <iframe src="https://devoop.cartrust.co.th/policy" class="iframe" scrolling="no"></iframe>
                        </div>
                      </v-card>
                    </div> -->
                    <v-row style="place-content: center; padding-top: 50px;">
                      <v-checkbox v-model="allow_submit" label="ฉันยอมรับเงื่อนไขและข้อกำหนด" style="margin-top: 0px"></v-checkbox>
                      <a target="_blank" href="https://devoop.cartrust.co.th/policy" style="margin-top: 5px">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>
                    </v-row>
                    <!-- <v-checkbox v-model="allow_submit" label="อนุญาตให้ทางเจ้าหน้าทีติดต่อกลับและใช้ข้อมูลของฉันเพื่อดำเนินการให้บริการอืนๆที่เกียวข้องกับรีไฟแนนซ์รถยนต์"></v-checkbox> -->
                    
                    <div style="place-content: center;">
                      <v-btn
                        color="#ed8b24"
                        @click.stop="
                          if ($refs['form1'].validate())
                          action();
                        "
                        :disabled = !allow_submit
                        style="color: white; font-size: medium; font-weight: bold; width: 300px; margin-bottom: 50px;"
                      >
                        ยืนยันข้อมูล
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <CallToAction />
    </v-container>
    <v-dialog v-model="policyDialog" persistent width="500">
      <v-card>
        <v-card-title class="white--text primary">
          การยินยอมให้เปิดเผยข้อมูล
        </v-card-title>

        <v-card-text class="pt-2">
          กรุณากดตกลง
          หากท่านอนุญาตให้ทางเจ้าหน้าทีติดต่อกลับและใช้ข้อมูลของท่านเพื่อดำเนินการให้บริการอืนๆที่เกียวข้องกับรีไฟแนนซ์รถยนต์
          <a href="https://devoop.cartrust.co.th/policy" target="_blank">อ่านรายละเอียดเพิ่มเติม</a>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="error" text @click="policyDialog = false">
            ยกเลิก
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading="disabled"
            :disabled="disabled"
            @click.stop="action"
          >
            ตกลง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="successDialog" width="500">
      <v-card>
        <v-card-title class="white--text primary">
          ขอบพระคุณสำหรับข้อมูล
        </v-card-title>

        <v-card-text class="pt-2">
          ทางเราได้รับข้อมูลของท่านเรียบร้อยแล้ว
          และจะมีเจ้าหน้าที่ติดต่อกลับไปภายใน 1 วันทำการ
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.stop="handleSuccess">
            เสร็จสิ้น
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Snackbar (Visual notification) -->
    <div class="text-center">
      <v-snackbar
        v-model="snackBarAlert.alert"
        :middle="true"
        :color="snackBarAlert.snackBarColor"
        :timeout="snackBarAlert.timeout"
      >
        {{ snackBarAlert.snackBarText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackBarAlert.alert = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <!-- End Snackbar (Visual notification) -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import formatCurrency from "@/filters/formatCurrency";
import goTo from "vuetify/lib/services/goto";
import CallToAction from '../landing/CallToAction.vue'

export default {
  components: {
    CallToAction
    // CarTrustHeader: () => import("./CarTrustHeader"),
    // CarTrustAgent: () => import("./CarTrustAgent"),
  },
  data: () => {
    return {
      allow_submit: false,
      OFFLINE_BRANCH: [],
      ONLINE_BRANCH: [],
      channel_select : "",
      step: "",
      current_branch: "",
      requiredRules: [(v) => !!v || "กรุณากรอกข้อมูล"],
      lengthRule: [(v) => v.length > 0 || "กรุณาเลือก"],
      valid1: false,
      successDialog: false,
      policyDialog: false,
      error: false,
      errorMessages: "",
      disabled: false,
      radios: [],
      requireImages: [
        {
          text: "บัตรประชาชน *",
          zoho: "LinkNationalId",
          img: null,
          preview: null,
          size: null,
          name: "",
          required: true,
        },
        {
          text: "ภาพใบหน้าจริงลูกค้า *",
          zoho: "LinkFace",
          img: null,
          preview: null,
          size: null,
          name: "",
          required: true,
        },
        {
          text: "ภาพเล่มทะเบียนหน้าที่ 4 *",
          zoho: "LinkCarBookPage4",
          img: null,
          preview: null,
          size: null,
          name: "",
          required: true,
        },
        {
          text: "ภาพรถเห็นทะเบียนชัดเจน *",
          zoho: "LinkCarLicense",
          img: null,
          preview: null,
          size: null,
          name: "",
          required: true,
        },
        {
          text: "ภาพกรมธรรม์ประกันภัยรถยนต์ (ถ้ามี)",
          zoho: "LinkCarInsurance",
          img: null,
          preview: null,
          size: null,
          name: "",
          required: false,
        },
      ],
      showImage: null,
      chosenFile: null,
      options: {
        locale: "en-US",
        prefix: "",
        suffix: "฿",
        length: 11,
        precision: 0,
      },
      options_fix: {
        locale: "en-US",
        prefix: "",
        suffix: "",
        length: 2,
        precision: 0,
      },
      properties: {
        hint: "",
      },
      interestsListFinance: [
        "1.1 - บริการจัดไฟแนนซ์รถยนต์ รถ Big Bike ทุกประเภท",
        "1.2 - บริการรีไฟแนนซ์รถยนต์ พร้อมบริการปิดเล่ม",
        "1.3 - บริการปิดไฟแนนซ์รถยนต์ ปิดเล่มรถแบ่งเบาภาระหนี้สิน",
        "1.4 - จำนำรถยนต์",
      ],
      interestsListInsurance: [
        "2.1 - บริการจัดหาเปรียบเทียบประกันรถยนต์ รถมอเตอร์ไซค์",
        "2.2 - บริการต่อ พรบ ทะเบียน รถยนต์",
        "2.3 - บริการซ่อมและตกแต่งรถยนต์",
      ],
      interestsListSell: [
        "3.1 - บริการตรวจสภาพรถยนต์ก่อนซื้อขายรถยนต์มือสอง",
        "3.2 - บริการรับซื้อและขายรถยนต์ Luxury Supercar",
        "3.3 - Marketplace สำหรับรถยนต์",
      ],
      interestsList: [
        "ปิดบัญชี",
        "จัดหาไฟแนนซ์",
        "จำนำจอด",
        "ซ่อมและตกแต่ง",
        "ประกันภัยรถยนต์",
        "พรบ",
        "ขายรถยนต์",
        "ตรวจสภาพรถยนต์",
        "บริการซื้อขายรถยนต์",
        "ต่อทะเบียนรถยนต์",
        "โอนเล่มทะเบียน",
      ],
      blackListStatus: [
        {
          value: "Blacklist",
          text: "มีประวัติ BlackList",
        },
        {
          value: "Clean Profile",
          text: "ไม่เคยติด BlackList",
        },

        {
          value: "Unknown",
          text: "ไม่ทราบ",
        },
      ],
      insuranceExpiration: [
        "ไม่มีประกันรถยนต์",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      insuranceCategory: [
        "ประกันรถยนต์ ชั้น 1",
        "ประกันรถยนต์ชั้น 2",
        "ประกันรถยนต์ชั้น 3",
        "ประกันรถยนต์ชั้น 3+",
        "ประกันรถยนต์ชั้น 2+",
      ],
      e1: 1,
      lead: {
        brand: "",
        model: "",
        year: "",
        sub_model: "",
        license: "",
        car_price: "",
        monthly_loan_payment: "",
        installment_left: "",
        finance_total: 0,
        loan_payment_status: "",
        black_list: "",
        insurance_expiration: "",
        insurance_category: "",
        loan_paid: "",
        current_finance_id: "",
        first_name: "",
        last_name: "",
        career: "",
        age: "",
        gender: "",
        phone1: "",
        phone2: "",
        zipcode: "",
        province: "",
        contact_time: "",
        notes_to_staff: "",
        images: null,
        LinkNationalId: {
          title: null,
          url: null,
          value: null,
        },
        LinkCarLicense: {
          title: null,
          url: null,
          value: null,
        },
        LinkCarBookPage4: {
          title: null,
          url: null,
          value: null,
        },
        LinkFace: {
          title: null,
          url: null,
          value: null,
        },
        interests: [],
        Lead_Form_ID: null,
        zoho_id: null,
        channel: '',
        referral: '',
        tagged_admin: "",
        current_insurance: "",
        car_type: "",
        temp_id: "",
        type_lead: "",
        kauto_product_select: ""
      },
      car_price: "โปรดเลือกรุ่นรถของคุณ",
      car_price_150_percent: "โปรดเลือกรุ่นรถของคุณ",
      loan_payment_status_list: [
        "ไม่เคยขาดผ่อน",
        "ขาดผ่อนชำระ 1 งวด",
        "ขาดผ่อนชำระ 2 งวด",
        "ขาดผ่อนชำระเกิน 3 งวด",
        "ผ่อนหมดแล้ว / มีเล่มแล้ว",
      ],
      loan_paid_list: [
        {
          value: "ผ่อนเกิน 50%",
          text: "ผ่อนชำระเกินครึ่งแล้ว",
        },
        {
          value: "ผ่อนยังไม่ถึง 50%",
          text: "ผ่อนชำระยังไม่ถึงครึ่ง",
        },

        {
          value: "ผ่อนหมดแล้ว",
          text: "ผ่อนหมดแล้ว",
        },
        {
          value: "มีเล่มแล้ว",
          text: "มีเล่มแล้ว",
        },
      ],
      current_finance_list: [
        {
          value: "เงินติดล้อ",
          text: "เงินติดล้อ",
        },
        {
          value: "BAY",
          text: "ธ.กรุงศรี",
        },
        {
          value: "Kbank",
          text: "ธ.กสิกร",
        },
        {
          value: "Kiatnakin",
          text: "ธ.เกียรตินาคิน",
        },
        {
          value: "Tisco",
          text: "ธ.ทิสโก้",
        },
        {
          value: "SCB",
          text: "ธ.ไทยพาณิชย์",
        },
        {
          value: "TThanachart",
          text: "ธ.ธนชาต",
        },
        {
          value: "ศรีสวัสดิ์",
          text: "ศรีสวัสดิ์",
        },
        {
          value: "ธนาคารทิสโก้/สมหวังเงินสั่งได้",
          text: "สมหวัง เงินสั่งได้",
        },
        {
          value: "อื่นๆ",
          text: "อื่นๆ",
        },
      ],
      career_list: [
        {
          value: "Farmers",
          text: "เกษตรกร",
        },
        {
          value: "Business Owner",
          text: "เจ้าของกิจการ",
        },
        {
          value: "Private Sector Employee",
          text: "พนักงานบริษัทเอกชน",
        },
        {
          value: "Unknown",
          text: "ไม่มีอาชีพ",
        },
        {
          value: "Self-Employed / Freelancer",
          text: "รับจ้าง / อิสระ",
        },
        {
          value: "Government Employee",
          text: "รับราชการ",
        },
      ],
      gender: ["ชาย / Male", "หญิง / Female"],
      contact_time: [
        "ช่วงเช้า (9:00 - 12:00น.)",
        "ช่วงบ่าย (13:00 - 17:00 น.)",
        "ทุกช่วงเวลา",
      ],
      province_list: [
        {
          ID: "3291521000001175461",
          Province: "กรุงเทพมหานคร",
          Region: "Central",
          Urban: "true",
          Urban1: "กรุงเทพฯ ปริมณฑล",
          province_code: "BKK",
        },
        {
          ID: "3291521000001175425",
          Province: "ยะลา",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "YLA",
        },
        {
          ID: "3291521000001175420",
          Province: "สุราษฎร์ธานี",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "SNI",
        },
        {
          ID: "3291521000001175415",
          Province: "สงขลา",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "SKA",
        },
        {
          ID: "3291521000001175410",
          Province: "สตูล",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "STN",
        },
        {
          ID: "3291521000001175405",
          Province: "ระนอง",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "RNG",
        },
        {
          ID: "3291521000001175400",
          Province: "ภูเก็ต",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PKT",
        },
        {
          ID: "3291521000001175395",
          Province: "พัทลุง",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PLG",
        },
        {
          ID: "3291521000001175390",
          Province: "พังงา",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PNA",
        },
        {
          ID: "3291521000001175385",
          Province: "ปัตตานี",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PTN",
        },
        {
          ID: "3291521000001175380",
          Province: "นราธิวาส",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "NWT",
        },
        {
          ID: "3291521000001175375",
          Province: "นครศรีธรรมราช",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "NRT",
        },
        {
          ID: "3291521000001175370",
          Province: "ตรัง",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "TRG",
        },
        {
          ID: "3291521000001175365",
          Province: "ชุมพร",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "CPN",
        },
        {
          ID: "3291521000001175360",
          Province: "กระบี่",
          Region: "South",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "KBI",
        },
        {
          ID: "3291521000001175355",
          Province: "ราชบุรี",
          Region: "West",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "RBR",
        },
        {
          ID: "3291521000001175350",
          Province: "เพชรบุรี",
          Region: "West",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PBI",
        },
        {
          ID: "3291521000001175345",
          Province: "ประจวบคีรีขันธ์",
          Region: "West",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PKN",
        },
        {
          ID: "3291521000001175340",
          Province: "ตาก",
          Region: "West",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "TAK",
        },
        {
          ID: "3291521000001175335",
          Province: "กาญจนบุรี",
          Region: "West",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "KRI",
        },
        {
          ID: "3291521000001175330",
          Province: "สระแก้ว",
          Region: "East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "SKW",
        },
        {
          ID: "3291521000001175325",
          Province: "ระยอง",
          Region: "East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "RYG",
        },
        {
          ID: "3291521000001175320",
          Province: "ปราจีนบุรี",
          Region: "East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PRI",
        },
        {
          ID: "3291521000001175315",
          Province: "ตราด",
          Region: "East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "TRT",
        },
        {
          ID: "3291521000001175310",
          Province: "ชลบุรี",
          Region: "East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "CBI",
        },
        {
          ID: "3291521000001175305",
          Province: "ฉะเชิงเทรา",
          Region: "East",
          Urban: "true",
          Urban1: "กรุงเทพฯ ปริมณฑล",
          province_code: "CCO",
        },
        {
          ID: "3291521000001175300",
          Province: "จันทบุรี",
          Region: "East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "CTI",
        },
        {
          ID: "3291521000001175295",
          Province: "อุทัยธานี",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "UTI",
        },
        {
          ID: "3291521000001175290",
          Province: "อ่างทอง",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "ATG",
        },
        {
          ID: "3291521000001175285",
          Province: "สระบุรี",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "SRI",
        },
        {
          ID: "3291521000001175280",
          Province: "สุพรรณบุรี",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "SPB",
        },
        {
          ID: "3291521000001175275",
          Province: "สุโขทัย",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "STI",
        },
        {
          ID: "3291521000001175270",
          Province: "สิงห์บุรี",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "SBR",
        },
        {
          ID: "3291521000001175265",
          Province: "สมุทรสาคร",
          Region: "Central",
          Urban: "true",
          Urban1: "กรุงเทพฯ ปริมณฑล",
          province_code: "SKN",
        },
        {
          ID: "3291521000001175260",
          Province: "สมุทรสงคราม",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "SKM",
        },
        {
          ID: "3291521000001175255",
          Province: "สมุทรปราการ",
          Region: "Central",
          Urban: "true",
          Urban1: "กรุงเทพฯ ปริมณฑล",
          province_code: "SPK",
        },
        {
          ID: "3291521000001175250",
          Province: "ลพบุรี",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "LRI",
        },
        {
          ID: "3291521000001175245",
          Province: "เพชรบูรณ์",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PNB",
        },
        {
          ID: "3291521000001175240",
          Province: "พิษณุโลก",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PLK",
        },
        {
          ID: "3291521000001175235",
          Province: "พิจิตร",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PCT",
        },
        {
          ID: "3291521000001175230",
          Province: "พระนครศรีอยุธยา",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "AYA",
        },
        {
          ID: "3291521000001175225",
          Province: "ปทุมธานี",
          Region: "Central",
          Urban: "true",
          Urban1: "กรุงเทพฯ ปริมณฑล",
          province_code: "PTE",
        },
        {
          ID: "3291521000001175220",
          Province: "นนทบุรี",
          Region: "Central",
          Urban: "true",
          Urban1: "กรุงเทพฯ ปริมณฑล",
          province_code: "NBI",
        },
        {
          ID: "3291521000001175215",
          Province: "นครสวรรค์",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "NSN",
        },
        {
          ID: "3291521000001175210",
          Province: "นครปฐม",
          Region: "Central",
          Urban: "true",
          Urban1: "กรุงเทพฯ ปริมณฑล",
          province_code: "NPT",
        },
        {
          ID: "3291521000001175205",
          Province: "นครนายก",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "NYK",
        },
        {
          ID: "3291521000001175200",
          Province: "ชัยนาท",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "CNT",
        },
        {
          ID: "3291521000001175195",
          Province: "กำแพงเพชร",
          Region: "Central",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "KPT",
        },
        {
          ID: "3291521000001175190",
          Province: "อำนาจเจริญ",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "ACR",
        },
        {
          ID: "3291521000001175185",
          Province: "อุบลราชธานี",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "UBN",
        },
        {
          ID: "3291521000001175180",
          Province: "อุดรธานี",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "UDN",
        },
        {
          ID: "3291521000001175175",
          Province: "หนองบัวลำภู",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "NBP",
        },
        {
          ID: "3291521000001175170",
          Province: "หนองคาย",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "NKI",
        },
        {
          ID: "3291521000001175165",
          Province: "ศรีสะเกษ",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "SSK",
        },
        {
          ID: "3291521000001175160",
          Province: "สุรินทร์",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "SRN",
        },
        {
          ID: "3291521000001175155",
          Province: "สกลนคร",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "SNK",
        },
        {
          ID: "3291521000001175150",
          Province: "เลย",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "LEI",
        },
        {
          ID: "3291521000001175145",
          Province: "ร้อยเอ็ด",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "RET",
        },
        {
          ID: "3291521000001175140",
          Province: "ยโสธร",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "YST",
        },
        {
          ID: "3291521000001175135",
          Province: "มุกดาหาร",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "MDH",
        },
        {
          ID: "3291521000001175130",
          Province: "มหาสารคาม",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "MKM",
        },
        {
          ID: "3291521000001175125",
          Province: "บุรีรัมย์",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "BRM",
        },
        {
          ID: "3291521000001175120",
          Province: "บึงกาฬ",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "BKN",
        },
        {
          ID: "3291521000001175115",
          Province: "นครราชสีมา",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "NMA",
        },
        {
          ID: "3291521000001175110",
          Province: "นครพนม",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "NPM",
        },
        {
          ID: "3291521000001175105",
          Province: "ชัยภูมิ",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "CPM",
        },
        {
          ID: "3291521000001175100",
          Province: "ขอนแก่น",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "KKN",
        },
        {
          ID: "3291521000001175095",
          Province: "กาฬสินธุ์",
          Region: "North East",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "KSN",
        },
        {
          ID: "3291521000001175090",
          Province: "อุตรดิตถ์",
          Region: "North",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "UTD",
        },
        {
          ID: "3291521000001175085",
          Province: "ลำพูน",
          Region: "North",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "LPN",
        },
        {
          ID: "3291521000001175080",
          Province: "ลำปาง",
          Region: "North",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "LPG",
        },
        {
          ID: "3291521000001175075",
          Province: "แม่ฮ่องสอน",
          Region: "North",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "MSN",
        },
        {
          ID: "3291521000001175070",
          Province: "แพร่",
          Region: "North",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PRE",
        },
        {
          ID: "3291521000001175065",
          Province: "พะเยา",
          Region: "North",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "PYO",
        },
        {
          ID: "3291521000001175060",
          Province: "น่าน",
          Region: "North",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "NAN",
        },
        {
          ID: "3291521000001175055",
          Province: "เชียงใหม่",
          Region: "North",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "CMI",
        },
        {
          ID: "3291521000001175050",
          Province: "เชียงราย",
          Region: "North",
          Urban: "false",
          Urban1: "ต่างจังหวัด",
          province_code: "CRI",
        },
      ],
      insuranceList: [
        'วิริยะ',
        'ไทยพานิชย์',
        'กรุงเทพ',
        'สินมั่นคง',
        'เมืองไทย',
        'เทเวศประกันภัย',
        'แอล เอ็ม จี',
        'ธนชาต',
        'มิตรแท้',
        'อาคเนย์',
        'สินทรัพย์',
        'AXA',
        'Alianz',
        'Generali',
        'MSIG'
      ],
      snackBarAlert: {
        alert: false,
        snackBarText: "",
        snackBarColor: null,
        timeout: 5000,
      }
    };
  },
  watch: {
    "channel_select": {
      handler(val) {
        this.lead.type_lead = val.type_lead
      }
    },
    "lead.brand": {
      handler(val) {
        console.log("lead.brand");
        this.lead.model = "";
        this.lead.year = "";
        this.lead.sub_model = "";
        this.CLEAR_RELATIVE_CAR_MODEL();
        this.CLEAR_VALID_YEARS();
        this.CLEAR_VALID_SUB_MODELS();
        this.fetchRelatedModel({ car_brand: val });
      },
    },
    "lead.model": {
      handler(val) {
        this.lead.year = "";
        this.lead.sub_model = "";
        this.CLEAR_VALID_YEARS();
        this.CLEAR_VALID_SUB_MODELS();
        this.CLEAR_VALID_PRICE();
        console.log("lead.model", val, this.lead.brand);
        if (val && this.lead.brand !== "") {
          this.fetchValidYear({ brand: this.lead.brand, model: val });
        }
      },
    },
    "lead.year": {
      handler(val) {
        this.lead.sub_model = "";
        this.CLEAR_VALID_SUB_MODELS();
        this.CLEAR_VALID_PRICE();
        console.log("lead.model", val, this.lead.brand);
        if (val && this.lead.brand !== "" && this.lead.model !== "") {
          this.fetchValidSubModel({
            brand: this.lead.brand,
            model: this.lead.model,
            year: val,
          });
        }
      },
    },
    "lead.sub_model": {
      handler(val) {
        this.CLEAR_VALID_PRICE();
        console.log("lead.model", val, this.lead.brand);
        if (
          val &&
          this.lead.brand !== "" &&
          this.lead.model !== "" &&
          this.lead.year !== ""
        ) {
          this.fetchValidPrice({
            brand: this.lead.brand,
            model: this.lead.model,
            year: this.lead.year,
            sub_model: val,
          });
        }
      },
    }
  },
  computed: {
    ...mapGetters("agent", [
      "getAllUsers",
      "getValidBrands",
      "getModels",
      "getValidYears",
      "getValidSubModelList",
      "getPrice",
      "getPrice150",
      "getNewLead",
      "getAgentInfo",
    ]),
    installmentLeftMonths() {
      return [...Array(100).keys()].map((i) => i + 1);
    },
    validPrice(priceString) {
      if (isNaN(priceString)) {
        console.log("format", formatCurrency(parseFloat(priceString)));
      }
      return priceString;
    },
    finance_total() {
      console.log(
        "finance_total",
        this.lead.monthly_loan_payment,
        this.lead.installment_left
      );
      if (
        this.lead.monthly_loan_payment == "" ||
        this.lead.installment_left == ""
      )
        return "0";
      return (
        parseInt(this.lead.monthly_loan_payment) *
        parseInt(this.lead.installment_left)
      );
    },
    validAge() {
      return (start, end) =>
        Array.from({ length: end - start }, (v, k) => k + start);
    },
  },
  mounted() {
    this.CLEAR_VALID_YEARS();
    this.CLEAR_VALID_SUB_MODELS();
    this.CLEAR_VALID_PRICE();
    this.CLEAR_RELATIVE_CAR_MODEL();
    this.CLEAR_NEW_LEAD();

    if (!this.getAgentInfo) {
      // this.fetchUserProfile();
    }
  },
  created() {
    this.fetchValidBrands().then((resp) => console.log(resp));
    this.fetchUserList()
    this.fetchLeadChannel().then(
      (resp) => {
        console.log('fetchLeadChannel : ', resp.detail)
        this.OFFLINE_BRANCH = resp.detail.Offline
        this.ONLINE_BRANCH = resp.detail.Online
        let id_event = this.$route.params.id_event
        const id = id_event.split("_")

        if (id.length > 1) {
          if (id[0] == "on") {
            for (const i in this.ONLINE_BRANCH) {
              if (id_event === this.ONLINE_BRANCH[i].id) {
                this.channel_select = this.ONLINE_BRANCH[i]
                this.lead.referral = this.ONLINE_BRANCH[i].name
                this.lead.channel = "Online"
              }
            }
          } else if (id[0] == "off") {
            for (const i in this.OFFLINE_BRANCH) {
              if (id_event === this.OFFLINE_BRANCH[i].id) {
                this.channel_select = this.OFFLINE_BRANCH[i]
                this.lead.referral = this.OFFLINE_BRANCH[i].name
                this.lead.channel = "Offline"
              }
            }
          }
        }
      }
    )
  },
  methods: {
    ...mapActions("agent", [
      "fetchUserList",
      "fetchValidBrands",
      "fetchRelatedModel",
      "fetchValidYear",
      "fetchValidSubModel",
      "fetchValidPrice",
      "createNewRecord",
      "uploadMultipleFiles",
      "fetchUserProfile",
      "createNewRecordReg",
      "fetchLeadChannel"
    ]),
    ...mapMutations("agent", [
      "CLEAR_VALID_YEARS",
      "CLEAR_VALID_SUB_MODELS",
      "CLEAR_VALID_PRICE",
      "CLEAR_RELATIVE_CAR_MODEL",
      "CLEAR_NEW_LEAD",
    ]),
    action() {
      this.disabled = true;
      this.lead.car_price = this.getPrice;
      this.lead.images = this.requireImages;
      this.lead.interests = this.radios;

      this.createNewRecordReg(this.lead)
        .then((resp) => {
          console.log("Create new record response", resp);
          this.policyDialog = false;
          this.successDialog = true;
          this.disabled = false;
          window.location.href =
            "https://m.me/cartrustthailand?ref=FormSubmitted--" +
            resp["lead_id"];

          setTimeout(() => {
            this.$router.go();
          }, 5000);
        })
        .catch((err) => {
          this.disabled = false;
          console.log("createNewRecord err", err);
          console.log("getNewLead", this.getNewLead);
          this.snackBarAlert.alert = true;
          this.snackBarAlert.snackBarText = err.response.data.response.detail;
          this.snackBarAlert.snackBarColor = "error";
          this.snackBarAlert.timeout = 3000;
        });

      // this.uploadMultipleFiles(this.lead.images)
      //   .then((resp) => {
      //     console.log("resp", resp);

      //     const zoho_details = resp.data.response.detail;

      //     if (zoho_details.length > 0) {
      //       // 1
      //       this.lead.LinkNationalId =
      //         zoho_details.find((item) => item.zoho === "LinkNationalId") ||
      //         this.lead.LinkNationalId;

      //       // 2
      //       this.lead.LinkFace =
      //         zoho_details.find((item) => item.zoho === "LinkFace") ||
      //         this.lead.LinkFace;

      //       // 3
      //       this.lead.LinkCarLicense =
      //         zoho_details.find((item) => item.zoho === "LinkCarLicense") ||
      //         this.lead.LinkCarLicense;
      //       // 4
      //       this.lead.LinkCarBookPage4 =
      //         zoho_details.find((item) => item.zoho === "LinkCarBookPage4") ||
      //         this.lead.LinkCarBookPage4;

      //       // Set id from backend
      //       this.lead.Lead_Form_ID = resp.data.response.id;
      //       this.lead.zoho_id = this.getAgentInfo.zoho_id;

      //       this.createNewRecord(this.lead)
      //         .then((resp) => {
      //           console.log("Create new record response", resp);
      //           this.policyDialog = false;
      //           this.successDialog = true;
      //           this.disabled = false;
      //           // window.location.href =
      //           //   "https://m.me/cartrustthailand?ref=FormSubmitted--" +
      //           //   data["results"]["id_lead"];
      //         })
      //         .catch((err) => {
      //           this.disabled = false;
      //           console.log("createNewRecord err", err);
      //           console.log("getNewLead", this.getNewLead);
      //           this.snackBarAlert.alert = true;
      //           this.snackBarAlert.snackBarText = "Cannot send information";
      //           this.snackBarAlert.snackBarColor = "error";
      //           this.snackBarAlert.timeout = 3000;
      //         });
      //     }
      //   })
      //   .catch((err) => {
      //     this.disabled = false;

      //     console.log("err", err);
      //     this.snackBarAlert.alert = true;
      //     this.snackBarAlert.snackBarText = "Cannot send information";
      //     this.snackBarAlert.snackBarColor = "error";
      //     this.snackBarAlert.timeout = 3000;
      //   });

      setTimeout(() => {
        this.disabled = false;
      }, 10000);
    },
    importImage(obj) {
      var reader = new FileReader();

      // Use the javascript reader object to load the contents
      // of the file in the v-model prop
      reader.readAsDataURL(obj.img);
      reader.onload = () => {
        obj.render = reader.result;
      };
    },
    previewImage: function (event, key) {
      console.log("event", event, key);
      var input = event;
      if (input) {
        var reader = new FileReader();
        console.log("reader", reader);

        reader.readAsDataURL(input);
        reader.onload = (e) => {
          const index = this.requireImages.findIndex(
            (item) => item.text === key
          );

          let formData = new FormData();

          formData.append("file", input);
          console.log("file", input, formData);

          this.requireImages[index].name = input.name;
          this.requireImages[index].size = input.size / 1024;
          this.requireImages[index].preview = e.target.result;
          this.requireImages[index].img = input;

          console.log("preview", key, e);
        };
      } else {
        const index = this.requireImages.findIndex((item) => item.text === key);
        this.requireImages[index].name = null;
        this.requireImages[index].size = null;
        this.requireImages[index].preview = null;
        this.requireImages[index].img = null;
      }
    },
    handleAlert() {
      this.snackBarAlert.alert = true;
      this.snackBarAlert.snackBarText = "Document updated";
      this.snackBarAlert.snackBarColor = "success";
      this.snackBarAlert.timeout = 3000;
    },
    handleInstallmentLeft(value) {
      if (isNaN(value)) this.lead.installment_left = "";
    },
    handleSuccess() {
      this.successDialog = false;
      this.$router.push("/lead");
    },
    handleMoveToStep(step, id, form) {
      if (form === null || this.$refs[form].validate()) {
        this.e1 = step;
        this.$refs[`form${step}`].resetValidation();
        goTo(id, { duration: 1000, offset: 0, easing: "easeInOutCubic" });
      }
    }
  },
};
</script>

<style>
.price_text {
  font-size: 40px;
  line-height: 26pt;
}
.my-iframe-container {
  overflow: hidden;
  height: 19660px;
}
.iframe {
  position: relative;
  top: -540px;
  left: 0px;
  bottom: -540px;
  width: 100%;
  height: 20200px;
}
@media {
  .my-iframe-container {
    height: 28560px;
  }
  .iframe {
    height: 29100px;
  }
}
</style>
